// src/pages/NewTask.jsx

import React, { useState, useEffect, useRef } from "react";
import { saveTask, getTaskableItems } from "../api/tasks"; // תיקון הנתיב
import { getProjects } from '../api/project';
import profileImagePlaceholder from "../assets/img/profile.svg"; // תיקון הנתיב
 import UpdateAssigneeDropdown from "../components/Tasks/UpdateAssigneeDropdown"; // תיקון הנתיב
 import { Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function NewTask() {
  const { t } = useTranslation();

  // מצבי רכיב
  const [editedTitle, setEditedTitle] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [editedDueDate, setEditedDueDate] = useState('');
  const [editedPhone, setEditedPhone] = useState('');
  const [editedEmail, setEditedEmail] = useState('');
  const [selectedTaskableType, setSelectedTaskableType] = useState('');
  const [selectedTaskableId, setSelectedTaskableId] = useState('');
  const [taskableItems, setTaskableItems] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('in_progress'); // ברירת מחדל
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const navigate = useNavigate();

  // סנכרון נתונים עם רכיבים חיצוניים אם נדרש

  useEffect(() => {
    if (selectedTaskableType) {
      fetchTaskableItems(selectedTaskableType);
    }
  }, [selectedTaskableType]);

  useEffect(() => {
    fetchProjects();
  }, []);

  // פונקציות לעבודה עם API
  const fetchTaskableItems = async (taskableType) => {
    try {
      const items = await getTaskableItems(taskableType);
      setTaskableItems(items);
    } catch (error) {
      console.error("Failed to fetch taskable items:", error);
      toast.error("שגיאה בטעינת פריטי משימה");
    }
  };

  const fetchProjects = async () => {
    try {
      const projectsData = await getProjects();
      setProjects(projectsData);
      setFilteredProjects(projectsData); // הצגת כל הפרויקטים תחילה
    } catch (error) {
      console.error('Error fetching projects:', error);
      toast.error('שגיאה בטעינת פרויקטים');
    }
  };

  // פונקציות לטיפול בשינויים בטפסים
  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);

    // סינון הפרויקטים לפי הטקסט
    const filtered = projects.filter(project =>
      project.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredProjects(filtered);

    // הצגת התפריט הנפתח כאשר יש תוצאות
    setShowDropdown(filtered.length > 0);
  };

  const handleProjectSelect = (project) => {
    setSelectedTaskableId(project.id);
    setSearchTerm(project.name); // הגדרת טקסט החיפוש לשם הפרויקט שנבחר
    setShowDropdown(false); // הסתרת התפריט הנפתח לאחר הבחירה
  };

  const handleInputChange = (field, value) => {
    switch (field) {
      case 'title':
        setEditedTitle(value);
        break;
      case 'description':
        setEditedDescription(value);
        break;
      case 'due_date':
        setEditedDueDate(value);
        break;
      case 'phone':
        setEditedPhone(value);
        break;
      case 'email':
        setEditedEmail(value);
        break;
      default:
        break;
    }
  };

  // פונקציה לטיפול בשליחת הטופס
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (editedTitle.trim() === "") {
      toast.error("כותרת המשימה אינה יכולה להיות ריקה");
      return;
    }

    const newTask = {
      subject: editedTitle,
      description: editedDescription,
      due_date: editedDueDate,
      phone: editedPhone,
      email: editedEmail,
      taskable_type: selectedTaskableType,
      taskable_id: selectedTaskableId,
      status: selectedStatus,
    };

    try {
      const response = await saveTask(newTask); // שימוש בפונקציה ליצירת משימה חדשה
      if (response.status === 201) { // בדיקת קוד הצלחה
        toast.success("משימה נוצרה בהצלחה");
        // אפס את השדות
        setEditedTitle('');
        setEditedDescription('');
        setEditedDueDate('');
        setEditedPhone('');
        setEditedEmail('');
        setSelectedTaskableType('');
        setSelectedTaskableId('');
        setSelectedStatus('in_progress');
        navigate('/tasks');
      } else {
        toast.success("משימה נוצרה בהצלחה");
        navigate('/tasks');
      }
    } catch (error) {
      console.error("Error creating task:", error);
      toast.error("שגיאה ביצירת המשימה");
    }
  };

  return (
    <div dir="rtl" className='container-fluid' style={{ backgroundColor: '#F3F3F5', padding: '2rem' }}>
      <div className="card p-4 bg-white shadow-lg">
        <h2 className="mb-4">יצירת משימה חדשה</h2>
        <form onSubmit={handleSubmit}>
          {/* כותרת המשימה */}
          <div className="form-group mb-3">
            <label>כותרת</label>
            <input
              type="text"
              value={editedTitle}
              onChange={(e) => handleInputChange('title', e.target.value)}
              className='form-control'
              placeholder="הכנס כותרת למשימה"
              required
            />
          </div>
          {/* תיאור המשימה */}
          <div className="form-group mb-3">
            <label>תיאור</label>
            <textarea
              className="form-control"
              value={editedDescription}
              onChange={(e) => handleInputChange('description', e.target.value)}
              placeholder="הכנס תיאור למשימה"
              rows="3"
            />
          </div>
          {/* תאריך יעד */}
          <div className="form-group mb-3">
            <label>תאריך יעד</label>
            <input
              type="date"
              className="form-control"
              value={editedDueDate}
              onChange={(e) => handleInputChange('due_date', e.target.value)}
            />
          </div>
          {/* טלפון */}
          <div className="form-group mb-3">
            <label>טלפון</label>
            <input
              type="tel"
              className="form-control"
              value={editedPhone}
              onChange={(e) => handleInputChange('phone', e.target.value)}
              placeholder="הכנס טלפון"
            />
          </div>
          {/* דוא"ל */}
          <div className="form-group mb-3">
            <label>דוא"ל</label>
            <input
              type="email"
              className="form-control"
              value={editedEmail}
              onChange={(e) => handleInputChange('email', e.target.value)}
              placeholder="הכנס דוא'ל"
              required
            />
          </div>
          {/* שיוך פרויקט */}
          <div className="form-group mb-3">
            <label>שיוך פרויקט</label>
            <Form className="m-0">
              <Form.Group controlId="projectSearch">
                <Form.Control
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="הכנס שם פרוייקט"
                  autoComplete="off"
                  onFocus={() => setShowDropdown(true)}
                />
                {showDropdown && (
                  <div className="autocomplete-dropdown" style={{ position: 'absolute', background: '#fff', border: '1px solid #ccc', zIndex: 1000, width: '100%' }}>
                    {filteredProjects.map(project => (
                      <div
                        key={project.id}
                        className="autocomplete-item"
                        onClick={() => handleProjectSelect(project)}
                        style={{ cursor: 'pointer', padding: '0.5rem', borderBottom: '1px solid #ccc' }}
                      >
                        {project.name}
                      </div>
                    ))}
                  </div>
                )}
              </Form.Group>
            </Form>
          </div>
          {/* סטטוס המשימה */}
          <div className="form-group mb-3">
            <label>סטטוס</label>
            <Form.Select
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              className='form-control'
            >
              <option value="in_progress">עבודה</option>
              <option value="on_hold">הקפאה</option>
              <option value="done">הסתיים</option>
            </Form.Select>
          </div>
          {/* שיוך למשתמש */}
          <div className="form-group mb-3">
            <label>שיוך משתמש</label>
            <UpdateAssigneeDropdown
              projectMembers={[]} // יש להגדיר או להעביר את הפרופס המתאימים
              selectedTask={{}} // במידה ונדרש
              setSelectedTask={() => {}} // במידה ונדרש
              tasks={[]} // במידה ונדרש
              setTasks={() => {}} // במידה ונדרש
            />
          </div>
          {/* כפתורי פעולה */}
          <div className="d-flex justify-content-between">
            <button type="submit" className='btn btn-primary'>שמירה</button>
            <button type="button" className='btn btn-secondary' onClick={() => {/* פעולת ניתוב או איפוס */}}>ביטול</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewTask;

import React, { useState, useEffect } from 'react';
import { getStations, updateStation, getProjectItems } from '../api/station';

function UpdateStationModal({ showUpdateStationModal, setShowUpdateStationModal, currentStation, setCurrentStation, fetchStations }) {
  const [stations, setStations] = useState([]);
  const [stationId, setStationId] = useState('');
  const [status, setStatus] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [installer, setInstaller] = useState('');
  const [errors, setErrors] = useState([]);
  const [items, setItems] = useState([
    { id: null, name: '', type: '', price: '', quantity: 1, completion_percentage: 0 },
  ]);

  useEffect(() => {
    if (currentStation) {
      // מילוי פרטים מהתחנה הנוכחית
      setStationId(currentStation.station_id || '');
      setStatus(currentStation.status || '');
      setDueDate(currentStation.due_date || '');
      setInstaller(currentStation.installer || '');
      
      // אם יש project_id נשלוף את המוצרים
      if (currentStation.project_id) {
        (async () => {
          try {
            const projectItems = await getProjectItems(currentStation.project_id);
            // אם אין פריטים, נשאיר פריט ריק אחד
            if (projectItems.length > 0) {
              setItems(projectItems.map(item => ({
                id: item.id,
                name: item.name,
                type: item.type,
                price: item.price || '',
                quantity: item.quantity,
                completion_percentage: item.completion_percentage || 0
              })));
            } else {
              setItems([{ id: null, name: '', type: '', price: '', quantity: 1, completion_percentage: 0 }]);
            }
          } catch (error) {
            setErrors([error.message]);
          }
        })();
      }
    } else {
      // אם אין currentStation, נאפס
      setItems([{ id: null, name: '', type: '', price: '', quantity: 1, completion_percentage: 0 }]);
    }
  }, [currentStation]);

  useEffect(() => {
    const fetchStationsData = async () => {
      try {
        const data = await getStations();
        setStations(data.stations);
      } catch (error) {
        setErrors([error.message]);
      }
    };
    fetchStationsData();
  }, []);

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index][field] = value;
    setItems(updatedItems);
  };

  const handleAddItem = () => {
    setItems([...items, { id: null, name: '', type: '', price: '', quantity: 1, completion_percentage: 0 }]);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const handleSave = async () => {
    try {
      if (!currentStation.project_id) {
        throw new Error('project_id is missing in currentStation');
      }

      if (stationId) {
        await updateStation({
          station_id: stationId,
          project_id: currentStation.project_id,
          status: status || null,
          due_date: dueDate || null,
          installer: installer || null,
          items
        });
        setShowUpdateStationModal(false);
        setCurrentStation(null);
        setErrors([]);
        fetchStations(); // רענון הרשימה לאחר שמירה
      } else {
        setErrors(['Please fill in all fields']);
      }
    } catch (error) {
      setErrors([error.message]);
    }
  };

  const handleClose = () => {
    setShowUpdateStationModal(false);
    setCurrentStation(null);
  };

  return (
    showUpdateStationModal && (
      <div className="modal fade show d-block" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">עדכון פרוייקט</h5>
              <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="stationId">תחנה</label>
                <select
                  className="form-control"
                  id="stationId"
                  value={stationId}
                  onChange={(e) => setStationId(e.target.value)}
                >
                  <option value=''>בחר תחנה</option>
                  {stations.map((station, index) => (
                    <option key={index} value={station.station_id}>
                      {station.station_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="status">סטטוס</label>
                <input
                  type="text"
                  className="form-control"
                  id="status"
                  placeholder="הכנס הערה"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="dueDate">תאריך סיום</label>
                <input
                  type="date"
                  className="form-control"
                  id="dueDate"
                  value={dueDate}
                  onChange={(e) => setDueDate(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="installer">מתקין</label>
                <input
                  type="text"
                  className="form-control"
                  id="installer"
                  placeholder="שם מתקין"
                  value={installer}
                  onChange={(e) => setInstaller(e.target.value)}
                />
              </div>
              {errors.length > 0 && (
                <div className="text-danger">
                  {errors.map((error, index) => (
                    <div key={index}>{error}</div>
                  ))}
                </div>
              )}
              <div className="form-group col-md-12 p-2">
                <h5>מוצרים</h5>
                {items.map((item, index) => (
                  <div key={index} className="mb-3 row">
                    <div className="col-md-6">
                      <label>שם מוצר</label>
                      <input
                        type="text"
                        placeholder="שם מוצר"
                        className="form-control mb-2"
                        value={item.name}
                        onChange={(e) => handleItemChange(index, 'name', e.target.value)}
                      />
                    </div>
                    <div className="col-md-4">
                      <label>סוג פריט</label>
                      <select className="form-control" value={item.type} onChange={(e) => handleItemChange(index, 'type', e.target.value)}>
                        <option value="">בחר סוג</option>
                        <option value="מטר אורך">מטר אורך</option>
                        <option value="מטר רוחב">מטר רוחב</option>
                        <option value="קומפלט">קומפלט</option>
                      </select>
                    </div>
                    <div className="col-md-4">
                      <label>מחיר</label>
                      <input
                        type="number"
                        placeholder="מחיר"
                        className="form-control mb-2"
                        value={item.price}
                        onChange={(e) => handleItemChange(index, 'price', e.target.value)}
                      />
                    </div>
                    <div className="col-md-4">
                      <label>כמות</label>
                      <input
                        type="number"
                        placeholder="כמות"
                        className="form-control mb-2"
                        value={item.quantity}
                        onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                      />
                    </div>
                    <div className="col-md-4">
                      <label>אחוז ביצוע</label>
                      <input
                        type="number"
                        placeholder="אחוז ביצוע"
                        className="form-control mb-2"
                        value={item.completion_percentage}
                        onChange={(e) => handleItemChange(index, 'completion_percentage', e.target.value)}
                      />
                    </div>
                    <button className="btn btn-danger btn-sm" onClick={() => handleRemoveItem(index)}>
                      הסר מוצר
                    </button>
                  </div>
                ))}
                <button className="btn btn-secondary" onClick={handleAddItem}>
                  הוסף מוצר
                </button>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleClose}>
                סגור
              </button>
              <button type="button" className="btn btn-primary" onClick={handleSave}>
                שמור
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default UpdateStationModal;

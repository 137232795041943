// src/pages/Projects.jsx

import React, { useEffect, useState } from 'react';
import { getMyProjects, switchProject } from '../api/project';
import ProjectsTable from '../components/projects/ProjectsTable';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Projects() {

    const [myProjects, setMyProjects] = useState([]);
    const navigate = useNavigate(); // שימוש ב-useNavigate להכוונה

    useEffect(() => {
        const fetchMyProjects = async () => {
            try {
                const fetchedMyProjects = await getMyProjects();
                setMyProjects(fetchedMyProjects);
            } catch (error) {
                console.error('Error fetching my projects:', error);
                toast.error('שגיאה בטעינת הפרויקטים שלך');
            }
        };

        fetchMyProjects();

    }, []);

    const handleSwitchProject = async (project_id) => {
        try {
            await switchProject(project_id);
            toast.success("פרויקט נבחר בהצלחה");
            navigate('/'); // ניווט לדף הבית לאחר בחירת פרויקט
        } catch (error) {
            console.error('Error switching project:', error);
            toast.error('שגיאה בבחירת הפרויקט');
        }
    }

    return (
        <div className='container-fluid'>
            <h2 className='bold ps-2'>פרויקטים</h2>
            <p className='text-muted mt-2 fw-500 ps-2 mb-5'>פרויקטים קיימים</p>
            <div className="row">
                <div className="col-12">
                    <ProjectsTable 
                        projects={myProjects} 
                        handleSwitchProject={handleSwitchProject} 
                    />
                </div>
            </div>
        </div>
    )
}

export default Projects;

import React, { useState, useEffect } from 'react';
import { saveCollection, updateCollection } from '../api/collections';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Tab, Tabs } from 'react-bootstrap';

function UpdateCollectionModal({ show, onHide, currentCollection, setCollections, collections, setCurrentCollection, onSuccess }) {
    const { t } = useTranslation();

    const [projectName, setProjectName] = useState('');
    const [projectManagerMobile, setProjectManagerMobile] = useState('');
    const [accountingManagerMobile, setAccountingManagerMobile] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [collectionContact, setCollectionContact] = useState('');

    const [retention5, setRetention5] = useState('');
    const [debt, setDebt] = useState('');
    const [lastInvoiceIssuanceDate, setLastInvoiceIssuanceDate] = useState('');
    const [collectedAmount, setCollectedAmount] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');

    const [firstLineSentWhatsapp, setFirstLineSentWhatsapp] = useState('');
    const [details, setDetails] = useState('');
    const [secondLineSentEmailDetails, setSecondLineSentEmailDetails] = useState('');
    const [details2, setDetails2] = useState('');
    const [call2Created, setCall2Created] = useState('');

    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (currentCollection) {
            setProjectName(currentCollection.project_name || '');
            setProjectManagerMobile(currentCollection.project_manager_mobile || '');
            setAccountingManagerMobile(currentCollection.accounting_manager_mobile || '');
            setEmail(currentCollection.email || '');
            setCompanyName(currentCollection.company_name || '');
            setCollectionContact(currentCollection.collection_contact || '');

            setRetention5(currentCollection.retention_5 || '');
            setDebt(currentCollection.debt || '');
            setLastInvoiceIssuanceDate(currentCollection.last_invoice_issuance_date || '');
            setCollectedAmount(currentCollection.collected_amount || '');
            setPaymentStatus(currentCollection.payment_status || '');

            setFirstLineSentWhatsapp(currentCollection.first_line_sent_whatsapp || '');
            setDetails(currentCollection.details || '');
            setSecondLineSentEmailDetails(currentCollection.second_line_sent_email_details || '');
            setDetails2(currentCollection.details_2 || '');
            setCall2Created(currentCollection.call_2_created || '');
        }
    }, [currentCollection]);

    const handleSubmit = async () => {
        const collection = {
            project_name: projectName,
            project_manager_mobile: projectManagerMobile,
            accounting_manager_mobile: accountingManagerMobile,
            email,
            company_name: companyName,
            collection_contact: collectionContact,

            retention_5: retention5,
            debt,
            last_invoice_issuance_date: lastInvoiceIssuanceDate,
            collected_amount: collectedAmount,
            payment_status: paymentStatus,
            project_id: currentCollection?.id || null,
            first_line_sent_whatsapp: firstLineSentWhatsapp,
            details,
            second_line_sent_email_details: secondLineSentEmailDetails,
            details_2: details2,
            call_2_created: call2Created
        };

        try {
            let response;
            if (currentCollection) {
                collection.id = currentCollection.id;
                response = await updateCollection(collection);
            } else {
                response = await saveCollection(collection);
            }

            onHide();
            onSuccess();
        } catch (error) {
            if (error.response && error.response.data.errors) {
                setErrors(Object.values(error.response.data.errors).flat());
            } else {
                setErrors([error.message]);
            }
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{currentCollection ? t('edit_collection') : t('create_collection')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey="project_details" className="mb-3">
                    <Tab eventKey="project_details" title="פרטי הפרויקט">
                        <div className="row">
                            <div className='col-md-6 p-2'>
                                <label>אחראי גבייה</label>
                                <select className='form-control' value={collectionContact} onChange={(e) => setCollectionContact(e.target.value)}>
                                    <option value=''>אחראי גבייה</option>
                                    <option value="מאיר">מאיר</option>
                                    <option value="ניסים">ניסים</option>
                                </select>
                            </div>
                            <div className='col-md-6 p-2'>
                                <label>{t('company_name')}</label>
                                <input type="text" className='form-control' value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                            </div>
                            <div className='col-md-6 p-2'>
                                <label>{t('project_name')}</label>
                                <input type="text" className='form-control' value={projectName} onChange={(e) => setProjectName(e.target.value)} />
                            </div>
                            <div className='col-md-6 p-2'>
                                <label>{t('project_manager_mobile')}</label>
                                <input type="text" className='form-control' value={projectManagerMobile} onChange={(e) => setProjectManagerMobile(e.target.value)} />
                            </div>
                            <div className='col-md-6 p-2'>
                                <label>{t('accounting_manager_mobile')}</label>
                                <input type="text" className='form-control' value={accountingManagerMobile} onChange={(e) => setAccountingManagerMobile(e.target.value)} />
                            </div>
                            <div className='col-md-6 p-2'>
                                <label>{t('email')}</label>
                                <input type="email" className='form-control' value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="collections" title="גבייה">
                        <div className="row">
                            <div className='col-md-6 p-2'>
                                <label>עכבון 5%</label>
                                <input type="text" className='form-control' value={retention5} onChange={(e) => setRetention5(e.target.value)} />
                            </div>
                            <div className='col-md-6 p-2'>
                                <label>{t('debt')}</label>
                                <input type="number" className='form-control' value={debt} onChange={(e) => setDebt(e.target.value)} />
                            </div>
                            <div className='col-md-6 p-2'>
                                <label>תאריך הוצאת חשבונית אחרון</label>
                                <input type="date" className='form-control' value={lastInvoiceIssuanceDate} onChange={(e) => setLastInvoiceIssuanceDate(e.target.value)} />
                            </div>
                            <div className='col-md-6 p-2'>
                                <label>מה נגבה</label>
                                <input type="number" className='form-control' value={collectedAmount} onChange={(e) => setCollectedAmount(e.target.value)} />
                            </div>
                            <div className='col-md-6 p-2'>
                                <label>אופן צורת תשלום</label>
                                <select className='form-control' value={paymentStatus} onChange={(e) => setPaymentStatus(e.target.value)}>
                                    <option value=''>אופן צורת תשלום</option>
                                    <option value="צ'ק">צק</option>
                                    <option value="מזומן">מזומן</option>
                                    <option value="העברה בנקאית">העברה בנקאית</option>
                                </select>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="status" title="סטטוס">
                        <div className="row">
                            <div className='col-md-6 p-2'>
                                <label>{t('first_line_sent_whatsapp')}</label>
                                <input type="text" className='form-control' value={firstLineSentWhatsapp} onChange={(e) => setFirstLineSentWhatsapp(e.target.value)} />
                            </div>
                            <div className='col-md-6 p-2'>
                                <label>{t('details')}</label>
                                <textarea className='form-control' style={{ height: 130 }} value={details} onChange={(e) => setDetails(e.target.value)} />
                            </div>
                            <div className='col-md-6 p-2'>
                                <label>{t('second_line_sent_email_details')}</label>
                                <textarea className='form-control' style={{ height: 130 }} value={secondLineSentEmailDetails} onChange={(e) => setSecondLineSentEmailDetails(e.target.value)} />
                            </div>
                            <div className='col-md-6 p-2'>
                                <label>{t('details_2')}</label>
                                <textarea className='form-control' style={{ height: 130 }} value={details2} onChange={(e) => setDetails2(e.target.value)} />
                            </div>
                            <div className='col-md-6 p-2'>
                                <label>{t('call_2_created')}</label>
                                <input type="text" className='form-control' value={call2Created} onChange={(e) => setCall2Created(e.target.value)} />
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSubmit}>{currentCollection ? t('update') : t('save')}</Button>
                <Button variant="secondary" onClick={onHide}>{t('close')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default UpdateCollectionModal;

// src/pages/Project.jsx

import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getProjectDetails } from '../api/project';
import { toast } from 'react-toastify';
import { Spinner, Container, Row, Col, Card, Button, Table, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Project() {
  const { id } = useParams(); // קבלת ה-ID מהנתיב
  const { t } = useTranslation();

  const [project, setProject] = useState({
    monthlyCollections: [],
    products:[],
    productions:[],
    tasks:[],
    collections:[],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const fetchedProject = await getProjectDetails(id);
        setProject(fetchedProject);
      } catch (err) {
        console.error('Error fetching project details:', err);
        toast.error('שגיאה בטעינת פרטי הפרויקט');
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
    console.log(project)
  }, [id]);

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">{t('Loading...')}</span>
        </Spinner>
      </Container>
    );
  }

  if (error || !project) {
    return (
      <Container className="mt-5 text-center">
        <h2>{t('פרויקט לא נמצא')}</h2>
        <p>{t('הפרויקט שביקשת אינו קיים או שיש בעיה בטעינתו.')}</p>
        <Button variant="primary" as={Link} to="/projects">
          {t('חזרה לרשימת הפרויקטים')}
        </Button>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <Row>
        {/* כרטיס הפרויקט */}
        <Col md={4}>
          <Card>
            <Card.Img 
              variant="top" 
              src={project.image || '/assets/img/projectPlaceholderIcon.jpg'} 
              alt={project.name} 
              style={{ height: '200px', objectFit: 'cover' }}
            />
            <Card.Body>
              <Card.Title>{project.name}</Card.Title>
              <Card.Text>{project.description || t('אין תיאור זמין')}</Card.Text>
              <Button variant="primary" as={Link} to="/tasks">
                {t('עבור למשימות')}
              </Button>
            </Card.Body>
          </Card>
        </Col>

        {/* פרטי הפרויקט */}
        <Col md={8}>
          <h3>{t('פרטי פרויקט')}</h3>
          <Row>
            <Col md={6}>
              <p><strong>{t('שם החברה')}:</strong> {project.company_name}</p>
              <p><strong>{t('מיקום')}:</strong> {project.location || t('לא צוין')}</p>
              <p><strong>{t('טלפון')}:</strong> {project.phone || t('לא צוין')}</p>
              <p><strong>{t('דוא"ל מנהל הפרויקט')}:</strong> {project.project_manager_email || t('לא צוין')}</p>
            </Col>
            <Col md={6}>
              <p><strong>{t('מנהל הפרויקט')}:</strong> {project.project_manager_name || t('לא צוין')}</p>
              <p><strong>{t('טלפון מנהל')}:</strong> {project.project_manager_phone || t('לא צוין')}</p>
              <p><strong>{t('מנהל חשבונות')}:</strong> {project.accounting_manager_name || t('לא צוין')}</p>
              <p><strong>{t('טלפון חשבונות')}:</strong> {project.accounting_phone || t('לא צוין')}</p>
            </Col>
          </Row>

          {/* קולקציות */}
          <h4 className="mt-4">{t('חשבוניות')}</h4>
          {project.monthlyCollections && project.monthlyCollections.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>חיוב</th>
                  <th>חודש</th>
                  <th>{t('פעולות')}</th>
                </tr>
              </thead>
              <tbody>
                
                {project.monthlyCollections.map((collection, index) => (
                  <tr key={collection.id}>
                    <td>{index + 1}</td>
                    <td>{collection.day}</td>
                    <td>{collection.year}</td>
                    <td>{collection.amount_collected}</td>
                    <td>
                      {/* פעולות נוספות ניתן להוסיף כאן */}
                      <Button variant="secondary" size="sm">
                        {t('עריכה')}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>{t('אין חשבוניות זמינות')}</p>
          )}

          {/* ייצור */}
          <h4 className="mt-4">{t('ייצור')}</h4>
          {project.productions && project.productions.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t('חברה')}</th>
                  <th>{t('סטטוס')}</th>
                </tr>
              </thead>
              <tbody>
                {project.productions.map((production, index) => (
                  <tr key={production.id}>
                    <td>{index + 1}</td>
                    <td>{production.company}</td>
                    <td>{production.status}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>{t('אין ייצור זמין.')}</p>
          )}

          {/* מוצרים */}
          <h4 className="mt-4">{t('מוצרים')}</h4>
          {project.productions && project.productions.length > 0 ? (
            <ul>
             
             <Table striped bordered hover responsive>
  <thead>
    <tr>
      <th>#</th>
      <th>פריטים</th>
    </tr>
  </thead>
  <tbody>
    {project.productions.map((production, index) => (
      <tr key={production.id}>
        <td>{index + 1}</td>

        <td>
          {production.items && production.items.length > 0 ? (
            <Table bordered>
              <thead>
                <tr>
                  <th>שם</th>
                  <th>סוג</th>
                  <th>כמות</th>
                  <th>אחוז ביצוע</th>
                  <th>מחיר</th>
                </tr>
              </thead>
              <tbody>
                {production.items.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.type}</td>
                    <td>{item.quantity}</td>
                    <td>{item.completion_percentage || 0}%</td>
                    <td>{item.price}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <span>אין פריטים</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          
            </ul>
          ) : (
            <p>{t('אין מוצרים זמינים.')}</p>
          )}

          {/* תחנות */}
          <h4 className="mt-4">{t('תחנות')}</h4>
          {project.stations && project.stations.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t('שם תחנה')}</th>
                  <th>{t('זמן כניסה')}</th>
                  <th>{t('תאריך יעד')}</th>
                </tr>
              </thead>
              <tbody>
                {project.stations.map((station, index) => (
                  <tr key={station.id}>
                    <td>{index + 1}</td>
                    <td>{station.name}</td>
                    <td>{new Date(station.entry_time).toLocaleString()}</td>
                    <td>{new Date(station.due_date).toLocaleDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>{t('אין תחנות זמינות.')}</p>
          )}

          {/* משימות */}
          <h4 className="mt-4">{t('משימות')}</h4>
          {project.tasks && project.tasks.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t('כותרת')}</th>
                  <th>{t('תיאור')}</th>
                  <th>{t('תאריך יעד')}</th>
                  <th>{t('סטטוס')}</th>
                  <th>{t('פעולות')}</th>
                </tr>
              </thead>
              <tbody>
                {project.tasks.map((task, index) => (
                  <tr key={task.id}>
                    <td>{index + 1}</td>
                    <td>{task.title}</td>
                    <td>{task.description || t('אין תיאור')}</td>
                    <td>{new Date(task.due_date).toLocaleDateString()}</td>
                    <td>{task.status}</td>
                    <td>
                      {/* ניתן להוסיף פעולות נוספות כמו עריכה או מחיקה */}
                      <Button variant="secondary" size="sm">
                        {t('עריכה')}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>{t('אין משימות זמינות.')}</p>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Project;
